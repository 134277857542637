import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import MetaData from '../../../components/Layout/MetaData.jsx';
import HowItWorks from '../common/HowItWorks/HowItWorks';
import CalculatorSection from '../common/CalculatorSection/CalculatorSection';
import WhyIwoca from 'aphrodite-shared/ui/WhyIwoca/WhyIwoca';
import WantToKnowMore from '../common/WantToKnowMore/WantToKnowMore';
import ReadAboutUs from '../common/ReadAboutUs/ReadAboutUs';
import Questions from '../common/Questions/Questions';
import Footer from '../../../components/Footer/Footer';
import { fetchIsLoggedIn } from '../../../util/authHelpers';
import HeroBannerSection from '../../../components/LegacyPage/HeroBannerSection';
import styles from '../iwocaPage.module.scss';
import NavBar from '../../../components/NavBar/NavBar';
import ComparisonTableSection from '../common/ComparisonTableSection/ComparisonTableSection.jsx';
import messages from './Broker.messages.js';

const BrokerPage = ({ data }) => {
    const { contentfulPageBroker: pageData, trustpilot } = data;
    const {
        title,
        metaDescription,
        contactUsSection,
        allowSearchEngineScraping = true,
        url,
        dataGaId,
        heroBusinessOwnerButton: mainCtaText,
        heroBusinessOwnerTitle,
        heroBusinessOwnerSubtitle,
        heroBusinessOwnerImage
    } = pageData;

    const isLoggedIn = fetchIsLoggedIn();

    const { formatMessage } = useIntl();
    const phoneNumber = formatMessage(messages.phone_number);
    const phoneNumberLink = formatMessage(messages.phone_number_link);
    const brokerSignupLink = formatMessage(messages.signup_link);
    const brokerSignupCta = formatMessage(messages.signup_cta);
    const brokerLoginLink = formatMessage(messages.login_link);

    // Object is in replacement of header data having to come from legacy page in contentful
    const headerBannerData = {
        headline: heroBusinessOwnerTitle,
        subtitleText: { subtitle: heroBusinessOwnerSubtitle },
        content: [{ image: heroBusinessOwnerImage }],
        button: {
            url: brokerSignupLink,
            buttonText: mainCtaText,
            dataGaId
        }
    };

    const footerCtaButton = {
        text: mainCtaText,
        href: brokerSignupLink,
        dataGaId
    };

    return (
        <div className={styles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />

            <NavBar
                phoneNumber={phoneNumber}
                phoneNumberLink={phoneNumberLink}
                ctaButton={{
                    text: brokerSignupCta,
                    href: brokerSignupLink,
                    dataGaId
                }}
                loginButton={{ href: brokerLoginLink }}
            />

            <HeroBannerSection data={headerBannerData} loggedIn={isLoggedIn} />

            <HowItWorks
                title={pageData.howItWorksTitle}
                steps={pageData.howItWorksSteps}
            />
            {pageData.comparisonTable && (
                <ComparisonTableSection
                    comparisonTableData={pageData.comparisonTable}
                />
            )}
            <CalculatorSection
                calculatorCopy={pageData.calculator}
                title={pageData.calculatorSectionTitle}
                subtitle={pageData.calculatorSectionSubtitle}
                intro={pageData.calculatorSectionIntro}
                contactBubble={pageData.contactBubble}
                noButtons={true}
            />
            <WhyIwoca
                title={pageData.whyIwocaTitle}
                points={pageData.whyIwocaPoints}
                button={mainCtaText}
                buttonCtaLink={brokerSignupLink}
                dataGaId={dataGaId}
            />
            <WantToKnowMore
                faq={pageData.faqSection.faqs}
                title={pageData.faqSection.title}
                subtitle={pageData.faqSection.subtitle.subtitle}
            />
            <ReadAboutUs
                desktopTitle={pageData.readAboutUsDesktopTitle}
                mobileTitle={pageData.readAboutUsMobileTitle}
                trustpilotRating={trustpilot.score.trustScore}
                pressReleaseIcons={pageData.pressReleaseSection.pressPhotos}
                trustpilotText={pageData.pressReleaseSection.trustpilotText}
            />
            <Questions
                title={contactUsSection.title}
                content={contactUsSection.content.content}
                email={pageData.contactUsSection.email}
                phoneNumber={pageData.contactUsSection.phoneNumber}
                showApplyButton={pageData.contactUsSection.showApplyButton}
                showJobsButton={pageData.contactUsSection.showJobsButton}
                jobCTAText={pageData.jobCtaText}
                jobLink={pageData.jobLink}
                loanCTAText={pageData.loanCtaText}
            />
            <Footer
                ctaButton={footerCtaButton}
                email={pageData.contactUsSection.email}
                phoneNumber={phoneNumber}
                phoneNumberLink={phoneNumberLink}
            />
        </div>
    );
};

export default BrokerPage;

export const query = graphql`
    query BrokerPageQuery($id: String!) {
        contentfulPageBroker(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            dataGaId
            heroBusinessOwnerTitle
            heroBusinessOwnerSubtitle
            heroBusinessOwnerButton
            heroBusinessOwnerImage {
                fixed(width: 1800, quality: 90) {
                    ...GatsbyContentfulFixed
                }
            }
            howItWorksTitle
            howItWorksSteps {
                ...HowItWorksStep
            }
            comparisonTable {
                ...comparisonTable
            }
            calculator {
                ...calculator
            }
            contactBubble {
                text
                phoneNumber
            }
            whyIwocaTitle
            whyIwocaPoints {
                ...whyIwoca
            }
            whyIwocaButton
            testimonialsHeading
            testimonialsHeading
            testimonialsParagraph {
                testimonialsParagraph
            }
            faqSection {
                ...faqSection
            }
            readAboutUsDesktopTitle
            readAboutUsMobileTitle
            pressReleaseSection {
                ...pressReleaseSection
            }
            contactUsSection {
                ...contactUsSection
            }
        }
        trustpilot {
            ...trustPilot
        }
    }

    fragment whyIwoca on ContentfulWhyIwoca {
        title
        description
    }

    fragment comparisonTable on ContentfulSectionComparisonTable {
        id
        title
        subheading
        options {
            ...comparisonOption
        }
    }

    fragment comparisonOption on ContentfulComparisonOption {
        id
        title
        subheading
        bulletpoints {
            ...bulletpoint
        }
    }

    fragment bulletpoint on ContentfulBulletpoint {
        id
        name
        text
        icon {
            fluid(maxWidth: 48, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
    }

    fragment contactUsSection on ContentfulContactUsQuestions {
        id
        title
        showApplyButton
        showJobsButton
        email
        phoneNumber
        content {
            content
        }
        email
        showApplyButton
        showJobsButton
        phoneNumber
    }
    fragment calculator on ContentfulNewRepaymentCalculator {
        borrowUpToText
        title
        mobileTitle
        totalRepaymentText
        subtitle
        equalRepaymentText
        forText
        helpText
        repaymentText
        footerText {
            footerText
        }
        ctaButton {
            buttonText
        }
        interestText
    }

    fragment faqSection on ContentfulSectionFaQs {
        title
        subtitle {
            subtitle
        }
        faqs {
            question
            answer
            color
            longAnswer {
                longAnswer
            }
        }
    }
`;
