import React from 'react';
import Img from 'gatsby-image';
import lodashHas from 'lodash/has';

import styles from './ComparisonTableSection.module.scss';
import ReactMarkdown from 'react-markdown';

const ComparisonTable = ({ comparisonTableData }) => {
    return (
        comparisonTableData.options && (
            <div className={styles.ComparisonTable}>
                {comparisonTableData.options.map((option) => (
                    <ComparisonOption key={option.id} optionData={option} />
                ))}
            </div>
        )
    );
};

export default ComparisonTable;

const ComparisonOption = ({ optionData }) => {
    return (
        <div className={styles.ComparisonOption}>
            <div className={styles.ComparisonOptionTitle}>
                <h3>{optionData.title}</h3>
                <p>{optionData.subheading}</p>
            </div>
            <div>
                {optionData.bulletpoints.map((bulletpoint) => (
                    <div className={styles.Bulletpoint} key={bulletpoint.id}>
                        {lodashHas(bulletpoint, 'icon.fluid') && (
                            <Img
                                fluid={bulletpoint.icon.fluid}
                                className={styles.bulletpointIcon}
                            />
                        )}
                        <div className={styles.bulletpointContent}>
                            <p className={styles.bulletpointTitle}>
                                <strong>{bulletpoint.name}</strong>
                            </p>
                            <ReactMarkdown
                                className={styles.bulletpointCopy}
                                source={bulletpoint.text}>
                                {bulletpoint.text}
                            </ReactMarkdown>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
