import React from 'react';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import styles from './HowItWorks.module.scss';

import HowItWorksShapes from './shapes/shapes';

const HowItWorks = ({ title, steps }) => {
    return (
        <section>
            <HowItWorksShapes>
                <div className={styles.HowItWorks}>
                    <h2>{title}</h2>
                    <hr />
                    <div className={styles.infoContainer}>
                        {steps &&
                            steps.map((step, i) => (
                                <div className={styles.info} key={i}>
                                    <div className={styles.icon}>
                                        <Img
                                            className={styles.hideMobile}
                                            fluid={step.icon.fluid}
                                        />
                                        <Img
                                            className={`${styles.showMobile} ${styles.mobileIcon}`}
                                            fluid={step.mobileIcon.fluid}
                                        />
                                    </div>
                                    <h3>{step.title}</h3>
                                    <ReactMarkdown
                                        className={styles.description}
                                        source={step.description.description}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </HowItWorksShapes>
        </section>
    );
};

export default HowItWorks;
