import React from 'react';
import classnames from 'classnames';
import ComparisonTable from './ComparisonTable';

import styles from './ComparisonTableSection.module.scss';
import { BasicDoughnut } from '../../../../SVGComponents/shapes';

const ComparisonTableSection = ({ comparisonTableData, className }) => {
    return (
        <div className={classnames(styles.ComparisonTableSection, className)}>
            <BasicDoughnut
                color="london75"
                className={styles.BasicLondonCircle}
                sm="sm"
            />
            <div className={styles.title}>
                <h2>{comparisonTableData.title}</h2>
                {comparisonTableData.subheading && (
                    <p>{comparisonTableData.subheading}</p>
                )}
            </div>
            <ComparisonTable comparisonTableData={comparisonTableData} />
        </div>
    );
};

export default ComparisonTableSection;
