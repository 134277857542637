import React from 'react';
import {
    BasicSquare,
    LongArch,
    BubbleArch
} from '../../../../../SVGComponents/shapes';

import styles from './shapes.module.scss';

const HowItWorksShapes = ({ children }) => {
    return (
        <div className={styles.shapeContainer}>
            <BasicSquare
                color="london75"
                rotate="left"
                className={styles.BasicLondonSquare}
            />

            <LongArch color="london" className={styles.LongLondonArch} />

            <BasicSquare
                color="denim"
                className={styles.BasicDenimSquare}
                size="lg"
            />
            <BasicSquare
                color="snow"
                size="sm"
                className={styles.BasicSnowSquare}
            />
            <BubbleArch className={styles.BubbleArch} />

            {children}
        </div>
    );
};

export default HowItWorksShapes;
