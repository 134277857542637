import React from 'react';
import { Link } from 'react-scroll';
import lodashGet from 'lodash/get';
import { ArchWithCircle } from 'aphrodite-shared/SVGComponents/shapes';
import styles from './HeroBannerSection.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';

const trackHeroBannerCta = (ctaText, productName) => {
    const getStartedCtaEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_HERO_BANNER_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(getStartedCtaEvent);
};

class HeroBannerSection extends React.Component {
    renderBackgroundImage(imageArray) {
        return (
            <div
                key={imageArray[0].id}
                className={styles.heroBackgroundImage}
                style={
                    lodashGet(imageArray[0].image, 'fixed.src') && {
                        backgroundImage: `url(${imageArray[0].image.fixed.src})`
                    }
                }
            />
        );
    }

    render() {
        const {
            data,
            broker = false,
            overflowShapes = { BasicDoughnut: true, BasicArch: true }
        } = this.props;

        const {
            headline,
            subtitleText,
            mobileSubtitleText,
            content,
            button,
            underButtonText
        } = data;
        return (
            <>
                <section className={styles.heroBanner}>
                    {this.renderBackgroundImage(content)}
                    <ArchWithCircle
                        className={styles.ArchWithCircle}
                        primaryColor="london75"
                        secondaryColor="denim10"
                    />
                    <div className={styles.heroContentContainer}>
                        <div className={styles.heroContent}>
                            {headline && <h1>{headline}</h1>}
                            {subtitleText && (
                                <h2 className={styles.subtitle}>
                                    {subtitleText.subtitle}
                                </h2>
                            )}
                            {mobileSubtitleText && (
                                <h2 className={styles.mobileSubtitle}>
                                    {mobileSubtitleText}
                                </h2>
                            )}
                            <div
                                className={
                                    broker
                                        ? styles.brokerButtonContainer
                                        : styles.buttonContainer
                                }>
                                {button &&
                                    (Array.isArray(button) ? (
                                        button.map((btn, index) => {
                                            if (btn.linkTo) {
                                                return (
                                                    <Button
                                                        key={btn.id}
                                                        href={btn.url}
                                                        colored
                                                        colour={
                                                            btn.colour ||
                                                            'primary'
                                                        }
                                                        className={
                                                            styles.button
                                                        }
                                                        title={btn.buttonText}
                                                        description={
                                                            btn.descriptionText
                                                        }
                                                        buttonClass={
                                                            btn.buttonClass
                                                        }>
                                                        <Link
                                                            data-ga-id={
                                                                btn.dataGaId ||
                                                                (index === 0
                                                                    ? 'hero__cbils_lp'
                                                                    : 'hero2__cta')
                                                            }
                                                            offset={-50}
                                                            to={btn.linkTo}
                                                            index="1"
                                                            duration={500}
                                                            smooth={true}>
                                                            {btn.buttonText}
                                                        </Link>
                                                    </Button>
                                                );
                                            } else {
                                                return (
                                                    <Button
                                                        key={btn.id}
                                                        href={btn.url}
                                                        colored
                                                        colour={
                                                            btn.colour ||
                                                            'primary'
                                                        }
                                                        dataGaId={
                                                            btn.dataGaId ||
                                                            (index === 0
                                                                ? 'hero__cbils_lp'
                                                                : 'hero2__cta')
                                                        }
                                                        className={
                                                            styles.button
                                                        }
                                                        title={btn.buttonText}
                                                        description={
                                                            btn.descriptionText
                                                        }
                                                        newTab={btn.newTab}
                                                        buttonClass={
                                                            btn.buttonClass
                                                        }>
                                                        {btn.buttonText}
                                                    </Button>
                                                );
                                            }
                                        })
                                    ) : (
                                        <>
                                            {/* Main Button on homepage */}
                                            <Button
                                                href={button.url}
                                                onClick={() => {
                                                    trackHeroBannerCta(
                                                        button.buttonText,
                                                        'Flexi-Loan'
                                                    );
                                                }}
                                                colored
                                                colour="primary"
                                                dataGaId={
                                                    button.dataGaId ||
                                                    'hero__cta'
                                                }
                                                description={
                                                    button.descriptionText
                                                }
                                                className={styles.button}
                                                buttonClass={button.buttonClass}
                                                title={button.buttonText}>
                                                {button.buttonText}
                                            </Button>
                                        </>
                                    ))}
                            </div>
                            {underButtonText && (
                                <p className={styles.underButtonText}>
                                    {underButtonText}
                                </p>
                            )}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

HeroBannerSection.defaultProps = {
    timeToTransition: 5000,
    loggedIn: false
};

export default HeroBannerSection;
