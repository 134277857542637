import { defineMessages } from 'react-intl';

export default defineMessages({
    phone_number: {
        id: '_.broker__phone_number',
        defaultMessage: '020 3434 3481'
    },
    phone_number_link: {
        id: '_.broker__phone_number_link',
        defaultMessage: 'tel:+442034343481'
    },
    signup_link: {
        id: '_.broker__broker_signup_link',
        defaultMessage: '/introducer/signup/'
    },
    signup_cta: {
        id: '_.broker__broker_signup_cta',
        defaultMessage: 'Apply'
    },
    login_link: {
        id: '_.broker__broker_login_link',
        defaultMessage: '/introducer/login/'
    }
});
