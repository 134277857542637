import React, { useContext } from 'react';
import classNames from 'classnames';
import { LongArch } from '../../../aphrodite-uk/src/SVGComponents/shapes';
import styles from './WhyIwoca.module.scss';
import ArrowSVG from 'aphrodite-shared/SVGComponents/ArrowSVG';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { animateScroll as scroll, Link, Element } from 'react-scroll';
import Button from '../Button/Button';

const WhyIwoca = ({
    title,
    points,
    nextLink,
    button,
    buttonCtaLink,
    buttonCtaColor = 'primary',
    dataGaId,
    className
}) => {
    const context = useContext(IwContext);

    function handleOpenStepZero() {
        context.openStepZero();
    }

    const ctaButtonProps = {
        colour: buttonCtaColor,
        dataGaId: dataGaId || 'whyIwoca__cbils_lp'
    };

    return (
        <Element name="WhyIwoca">
            <section className={classNames(styles.background, className)}>
                <div className={styles.WhyIwoca}>
                    <h2>{title}</h2>
                    <hr />
                    <div className={styles.whyIwocaContainer}>
                        {points &&
                            points.map((point, i) => (
                                <div className={styles.whyIwocaList} key={i}>
                                    <h3>{point.title}</h3>
                                    <p>{point.description}</p>
                                </div>
                            ))}
                    </div>
                    {button &&
                        (buttonCtaLink ? (
                            <Button href={buttonCtaLink} {...ctaButtonProps}>
                                {button}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleOpenStepZero}
                                {...ctaButtonProps}>
                                {button}
                            </Button>
                        ))}
                    {nextLink && (
                        <Link
                            offset={-50}
                            to={nextLink}
                            index="1"
                            duration={500}
                            smooth={true}>
                            <ArrowSVG className={styles.arrow} />
                        </Link>
                    )}
                </div>
            </section>
        </Element>
    );
};

export default WhyIwoca;
