import React from 'react';
import styles from './ReadAboutUs.module.scss';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

const ReadAboutUs = ({
    desktopTitle,
    mobileTitle,
    pressReleaseIcons,
    className,
    displayBbbLogo = true
}) => {
    return (
        <section className={styles.background}>
            <div className={classnames(styles.ReadAboutUs, className)}>
                <h2 className={styles.hideMobile}>{desktopTitle}</h2>
                <h2 className={styles.showMobile}>{mobileTitle}</h2>
                <hr />
                <div className={styles.logosContainer}>
                    <DynamicTrustpilot className={styles.TrustpilotLogo} />
                    <div
                        className={cx(styles.dynamicLogosContainer, {
                            dynamicLogosContainerNoBBB: displayBbbLogo
                        })}>
                        <div
                            className={cx(styles.PressLogo, {
                                forbesLogo: displayBbbLogo
                            })}>
                            <img
                                src={pressReleaseIcons[0].photos[0].file.url}
                                alt="Fobes logo"
                            />
                        </div>
                        {displayBbbLogo && (
                            <div
                                className={cx(styles.PressLogo, {
                                    xeroLogo: displayBbbLogo
                                })}>
                                <img
                                    alt="xeroLogo"
                                    src="https://images.ctfassets.net/835lsoe3tdrh/502js950Vu5BuKNc0PlXct/ec428d3b54e2cefec7b1e2898d3ab9b7/xero.png"
                                />
                            </div>
                        )}
                        <div
                            className={cx(styles.PressLogo, {
                                wiredLogo: displayBbbLogo
                            })}>
                            <img
                                src={pressReleaseIcons[1].photos[0].file.url}
                                alt="Wired logo"
                            />
                        </div>
                        <div
                            className={cx(styles.PressLogo, {
                                ftLogo: displayBbbLogo
                            })}>
                            <img
                                src={pressReleaseIcons[2].photos[0].file.url}
                                alt="Financial times logo"
                            />
                        </div>
                        <div className={styles.PressLogo}>
                            <img
                                src={pressReleaseIcons[3].photos[0].file.url}
                                alt="Yahoo finance logo"
                            />
                        </div>
                    </div>
                    {!displayBbbLogo && (
                        <div className={styles.logoContainer}>
                            <div className={styles.xeroLogo}>
                                <img
                                    alt="xeroLogo"
                                    src="https://images.ctfassets.net/835lsoe3tdrh/502js950Vu5BuKNc0PlXct/ec428d3b54e2cefec7b1e2898d3ab9b7/xero.png"
                                />
                            </div>
                            <div className={styles.bbbLogo}>
                                <img
                                    alt="bbbLogo"
                                    src="https://images.ctfassets.net/835lsoe3tdrh/7Ks2zT2qJNPZMDVrdaQ9NV/9fdfc5ef4bf02f6cd51db99f25727741/bbb-logo.png"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ReadAboutUs;
